import { useQuery } from '@tanstack/react-query'

import { ISearchKeywordsResponse } from '@/shared/types'

import { withStrapiAxios } from '../api'

export const getSearchKeywords = async () => {
  const url = '/api/search-keywords'
  const {
    data: { keywords },
  } = await withStrapiAxios<ISearchKeywordsResponse>({ url })

  return keywords
}

export const useGetSearchKeywords = () => {
  return useQuery(['getSearchKeywords'], () => getSearchKeywords(), {
    refetchOnWindowFocus: false,
    suspense: true,
    useErrorBoundary: true,
  })
}
