/* eslint-disable @next/next/no-img-element */
import { Space } from '@mantine/core'

import { VerifiedBadge } from '@/shared/assets'

import ChainWithCategoryTag from './ChainWithCategoryTag'

interface IOriginalsItemProps {
  originalsId: string
  originalsTitle: string
  chainId: string
  categoryTitle: string
  image: string | null
  handleClick: (originalsId: string) => void
}

const OriginalsItem = ({
  originalsId,
  originalsTitle,
  chainId,
  categoryTitle,
  image,
  handleClick,
}: IOriginalsItemProps) => {
  return (
    <div className="flex flex-col">
      <div className="relative">
        <img
          alt={originalsTitle}
          src={image ?? '/img/placeholder/item-card-replace.png'}
          onClick={() => handleClick(originalsId)}
          className="w-full h-[189px] rounded-[6px] object-cover"
        />
        <img
          alt="skeleton"
          src="/img/icon/search-originals-skeleton-top-left.svg"
          className="absolute left-0 -top-[1px]"
        />

        <img
          alt="skeleton"
          src="/img/icon/search-originals-skeleton-bottom-right.svg"
          className="absolute right-0 -bottom-[1px]"
        />
      </div>
      <Space h={12} />
      <ChainWithCategoryTag chainId={chainId} categoryTitle={categoryTitle} />
      <Space h={8} />
      <div className="flex items-center gap-x-1">
        <p className="text-lg font-medium leading-[23px] text-appTextPrimary">{originalsTitle}</p>
        <VerifiedBadge />
      </div>
    </div>
  )
}

const OriginalsItemSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="relative w-full h-[218px] rounded-[10px] bg-appBgQuaternary">
        <img
          alt="skeleton"
          src="/img/icon/search-originals-skeleton-top-left.svg"
          className="absolute left-0 -top-[1px]"
        />

        <img
          alt="skeleton"
          src="/img/icon/search-originals-skeleton-bottom-right.svg"
          className="absolute right-0 -bottom-[1px]"
        />
      </div>
      <Space h={12} />
      <div className="w-[100px] h-[23px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={8} />
      <div className="w-[300px] h-[23px] rounded-[2px] bg-appBgQuaternary" />
    </div>
  )
}

export default OriginalsItem
OriginalsItem.Skeleton = OriginalsItemSkeleton
