import { Space } from '@mantine/core'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Suspense, useCallback } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { CommonErrorFallback } from '@/components/common'
import { useGetSearchKeywords } from '@/shared/services/strapi'
import { ISearchKeywordsData } from '@/shared/types'

import SearchSectionTitle from './SearchSectionTitle'

const SuggestedKeywords = () => {
  const { data } = useGetSearchKeywords()
  const { push } = useRouter()

  const handleClick = useCallback(
    (url: string) => {
      push(url)
    },
    [push],
  )

  if (data === undefined) return null

  return <SuggestedKeywords.Design data={data} handleClick={handleClick} />
}

const SuggestedKeywordsDesign = ({
  data,
  handleClick,
}: {
  data: ISearchKeywordsData[]
  handleClick: (url: string) => void
}) => {
  const { t } = useTranslation('web-search')
  if (data.length === 0) return null

  return (
    <>
      <div className="px-4">
        <SearchSectionTitle title={t('web-search:search.recommendation-title')} />
        <Space h={24} />
        <div className="flex flex-wrap gap-3">
          {data.map(keyword => (
            <div
              key={`search-keyword-item-${keyword.id}`}
              onClick={() => handleClick(keyword.destinationUrl)}
              className="font-normal leading-normal text-white bg-appBgSecondary p-2 w-fit rounded-sm">
              {keyword.keywordTitle}
            </div>
          ))}
        </div>
      </div>

      <Space h={60} />
    </>
  )
}

const SuggestedKeywordsSkeleton = () => {
  return (
    <>
      <div className="px-4">
        <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
        <Space h={24} />
        <div className="flex gap-3">
          {range(3).map(index => (
            <div
              key={`search-keyword-item-skeleton-${index}`}
              className="w-[90px] h-[34px] rounded-[2px] bg-appBgQuaternary"
            />
          ))}
        </div>
      </div>

      <Space h={60} />
    </>
  )
}

const SuggestedKeywordsErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  return (
    <>
      <div className="px-4">
        <SearchSectionTitle title={t('web-search:search.recommendation-title')} />
        <Space h={24} />
        <CommonErrorFallback
          bgColor="bg-appBgSecondary"
          title={t('common:error-data-keywords')}
          handleRetry={resetErrorBoundary}
          error={error}
        />
      </div>

      <Space h={60} />
    </>
  )
}

const SuggestedKeywordsWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary FallbackComponent={SuggestedKeywordsErrorFallback} onReset={reset}>
      <Suspense fallback={<SuggestedKeywordsSkeleton />}>
        <SuggestedKeywords />
      </Suspense>
    </ErrorBoundary>
  )
}

export default SuggestedKeywords

SuggestedKeywords.Skeleton = SuggestedKeywordsSkeleton
SuggestedKeywords.WithErrorBoundary = SuggestedKeywordsWithErrorBoundary
SuggestedKeywords.ErrorFallback = SuggestedKeywordsErrorFallback
SuggestedKeywords.Design = SuggestedKeywordsDesign
