/* eslint-disable @next/next/no-img-element */

import { Space } from '@mantine/core'
import { ReactElement } from 'react'

import { SEARCH_HOME_LIST_ITEM_THEME } from '@/shared/constants/search'
import { cx } from '@/shared/utils'

interface IDescriptionProps {
  description: string | ReactElement
  descriptionWrapperClass?: string
}

const Description = ({ description, descriptionWrapperClass }: IDescriptionProps) => (
  <div className={cx('line-clamp-2', descriptionWrapperClass)}>{description}</div>
)

const TwoLineItem = ({
  title,
  Badge,
  isLast,
  description,
  descriptionWrapperClass,
  descriptionDirection = 'top',
}: {
  title: string
  isLast: boolean
  Badge?: () => JSX.Element
  descriptionWrapperClass?: string
  description: string | ReactElement
  descriptionDirection?: 'top' | 'bottom'
}) => {
  return (
    <div className={cx('w-full', !isLast ? 'border-b border-b-appBorderQuarternary' : '')}>
      <div className="flex flex-col gap-y-2">
        {descriptionDirection === 'top' && (
          <Description
            description={description}
            descriptionWrapperClass={descriptionWrapperClass}
          />
        )}

        <div className="flex gap-1 items-center">
          <p className="text-base font-medium leading-[20p] line-clamp-2">{title}</p>
          {Badge && <Badge />}
        </div>

        {descriptionDirection === 'bottom' && (
          <Description
            description={description}
            descriptionWrapperClass={descriptionWrapperClass}
          />
        )}
      </div>
    </div>
  )
}

const ThreeLineItem = ({
  title,
  subTitle,
  description,
  Badge,
  isLast,
}: {
  title: string
  subTitle: string
  description: string | ReactElement
  Badge?: () => JSX.Element
  isLast: boolean
}) => {
  return (
    <div className={cx('w-full pb-2', !isLast ? 'border-b border-b-appBorderQuarternary' : '')}>
      <div>
        <div className="flex gap-1 items-center">
          <div className="text-xs text-appTextTertiary font-medium leading-[15px] truncate">
            {subTitle}
          </div>
          {Badge && <Badge />}
        </div>
        <Space h={4} />
        <h1 className="text-sm font-semibold leading-[18px] text-appTextPrimary truncate">
          {title}
        </h1>
        <Space h={12} />
        {description}
      </div>
    </div>
  )
}

const SearchHomeListItem = ({
  theme = SEARCH_HOME_LIST_ITEM_THEME.twoLineSection,
  content: { title, subTitle, description, image, Badge, handleClick },
  isLast,
  descriptionWrapperClass,
  descriptionDirection,
}: {
  theme?: keyof typeof SEARCH_HOME_LIST_ITEM_THEME
  content: {
    title: string
    subTitle?: string
    description: string | ReactElement
    image: string
    Badge?: () => JSX.Element
    handleClick?: () => void
  }
  descriptionWrapperClass?: string
  isLast: boolean
  descriptionDirection?: 'top' | 'bottom'
}) => {
  const sectionThemeMappings = {
    [SEARCH_HOME_LIST_ITEM_THEME.twoLineSection]: (
      <TwoLineItem
        title={title}
        description={description}
        Badge={Badge}
        isLast={isLast}
        descriptionWrapperClass={descriptionWrapperClass}
        descriptionDirection={descriptionDirection}
      />
    ),
    [SEARCH_HOME_LIST_ITEM_THEME.threeLineSection]: (
      <ThreeLineItem
        title={title}
        subTitle={subTitle as string}
        description={description}
        Badge={Badge}
        isLast={isLast}
      />
    ),
  }

  return (
    <div className="w-full flex gap-4" onClick={handleClick}>
      <div className="square-item aspect-square overflow-hidden shrink-0 rounded-md bg-appBgTertiary">
        <img
          src={image !== '' ? image : '/img/placeholder/item-card-replace.png'}
          onError={e => {
            e.currentTarget.src = '/img/placeholder/item-card-replace.png'
          }}
          className="object-cover w-full h-full"
          alt="list image"
        />
      </div>
      {sectionThemeMappings[theme]}
    </div>
  )
}

const SearchHomeListItemSkeleton = () => {
  return (
    <div className="flex gap-4">
      <div className="square-item overflow-hidden shrink-0 rounded-md bg-appBgQuaternary">
        <div className="aspect-square bg-appBgQuaternary" />
      </div>

      <div className="flex flex-col justify-between">
        <div className="w-full flex flex-col gap-y-1">
          <div className="w-[100px] h-[15px] rounded-[2px] bg-appBgQuaternary" />
          <div className="w-[200px] h-[18px] rounded-[2px] bg-appBgQuaternary" />
        </div>

        <div className="w-full h-[1px] bg-appBgQuaternary" />
      </div>
    </div>
  )
}

export default SearchHomeListItem
SearchHomeListItem.Skeleton = SearchHomeListItemSkeleton
