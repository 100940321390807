import { useQuery } from '@tanstack/react-query'

import { ISearchOnboardingsResponse } from '@/shared/types'
import { searchOnboardingsTransformer } from '@/shared/utils'

import { withStrapiAxios } from '../api'

export const getSearchOnboardings = async () => {
  const url = '/api/search-onboardings'
  const { data } = await withStrapiAxios<ISearchOnboardingsResponse>({ url })

  return data
}

export const useGetSearchOnboardings = () => {
  return useQuery(['getSearchOnboardings'], () => getSearchOnboardings(), {
    refetchOnWindowFocus: false,
    suspense: true,
    useErrorBoundary: true,
    select: data => searchOnboardingsTransformer(data),
  })
}
