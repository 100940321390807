import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'
import { Control, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SearchFormFields } from '@/shared/types'

import { TextInput } from '../core-ui'

const SearchInput = ({
  control,
  reset,
}: {
  control: Control<SearchFormFields>
  reset: () => void
}) => {
  const { t } = useTranslation('web-search')
  const ref = useRef<HTMLInputElement>(null)

  const { query } = useRouter()
  const { searchText } = query as { searchText?: string }

  const {
    field: { value, onChange },
  } = useController({
    name: 'searchText',
    defaultValue: searchText ?? '',
    control,
    rules: {
      required: true,
    },
  })

  const hideMobileKeyboardOnReturn = useCallback((keyboardEvent: KeyboardEvent) => {
    if (ref.current === null) return
    const key = keyboardEvent.code || keyboardEvent.key
    if (key === 'Enter' || key === '13') {
      ref.current.blur()
    }
  }, [])

  useEffect(() => {
    ;() => {
      window.addEventListener('keyup', hideMobileKeyboardOnReturn)
      document.addEventListener('keyup', hideMobileKeyboardOnReturn)
    }

    return () => {
      window.removeEventListener('keyup', hideMobileKeyboardOnReturn)
      document.removeEventListener('keyup', hideMobileKeyboardOnReturn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="px-4 py-4">
      <TextInput
        ref={ref}
        placeholder={t('search.empty-description')}
        value={value || ''}
        onChange={e => onChange(e)}
        leftIconPath={
          value === undefined || value === '' ? '/img/icon/search-tertiary.svg' : undefined
        }
        reset={() => reset()}
      />
    </div>
  )
}

export default SearchInput
