import { Space } from '@mantine/core'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Suspense, useCallback } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { CommonErrorFallback } from '@/components/common'
import { ToolTip } from '@/components/core-ui'
import { VerifiedBadge } from '@/shared/assets'
import { ROUTE_PATH } from '@/shared/constants'
import { useGetSearchCollections } from '@/shared/services/strapi'
import { ISearchAllCollectionsData } from '@/shared/types'

import ChainWithCategoryTag from '../ChainWithCategoryTag'
import SearchHomeListItem from '../SearchHomeListItem'
import SearchSectionTitle from './SearchSectionTitle'

const AllCollections = () => {
  const { push } = useRouter()
  const { data } = useGetSearchCollections()

  const handleClick = useCallback(
    (collectionAddress: string) => {
      push(ROUTE_PATH.COLLECTION_DETAIL.replace(':collectionAddress', collectionAddress))
    },
    [push],
  )
  if (data === undefined) return null

  return <AllCollections.Design data={data} handleClick={handleClick} />
}

const AllCollectionsDesign = ({
  data,
  handleClick,
}: {
  data: ISearchAllCollectionsData
  handleClick: (collectionAddress: string) => void
}) => {
  const { t } = useTranslation(['common', 'web-search'])

  const { title, moreText, internalUrl, items } = data

  const lastIndex = items.length - 1

  if (items.length === 0) return null

  return (
    <div className="px-4">
      <SearchSectionTitle title={title} moreText={moreText} moreTextLink={internalUrl} />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {items.map((item, index) => (
          <SearchHomeListItem
            key={`all-collections-${item.title}`}
            content={{
              title: item.title,
              description: (
                <ChainWithCategoryTag chainId={item.chainId} categoryTitle={item.categoryTitle} />
              ),
              image: item.image,
              Badge: item.isOriginals
                ? () => (
                    <ToolTip
                      text={t('common:common.label-konkrit-originals')}
                      direction="bottom"
                      arrowSize={12}>
                      <VerifiedBadge />
                    </ToolTip>
                  )
                : undefined,
              handleClick: () => {
                handleClick(item.collectionAddress)
              },
            }}
            isLast={index === lastIndex}
          />
        ))}
      </div>
    </div>
  )
}

const AllCollectionsSkeleton = () => {
  return (
    <div className="px-4">
      <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {range(5).map(index => (
          <SearchHomeListItem.Skeleton key={`all-collections-skeleton-${index}`} />
        ))}
      </div>
    </div>
  )
}

const AllCollectionsErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-search'])

  const { data } = useGetSearchCollections()

  return (
    <div className="px-4">
      <SearchSectionTitle
        title={data?.title ?? `${t('common:common.collection-all-collections')}`}
        moreText={data?.moreText ?? `${t('common:common.cta-label-view-all')}`}
        moreTextLink={data?.internalUrl ?? '/collections'}
      />

      <Space h={24} />

      <CommonErrorFallback
        bgColor="bg-appBgSecondary"
        title={t('common:common.error-data-originals')}
        handleRetry={resetErrorBoundary}
        error={error}
      />
    </div>
  )
}

const AllCollectionsWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary FallbackComponent={AllCollections.ErrorFallback} onReset={reset}>
      <Suspense fallback={<AllCollections.Skeleton />}>
        <AllCollections />
      </Suspense>
    </ErrorBoundary>
  )
}

export default AllCollections

AllCollections.ErrorFallback = AllCollectionsErrorFallback
AllCollections.Skeleton = AllCollectionsSkeleton
AllCollections.Design = AllCollectionsDesign
AllCollections.WithErrorBoundary = AllCollectionsWithErrorBoundary
