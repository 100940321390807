import { useQuery } from '@tanstack/react-query'

import { ISearchCollectionsResponse } from '@/shared/types'
import { searchCollectionsTransformer } from '@/shared/utils'

import { withStrapiAxios } from '../api'

export const getSearchCollections = async () => {
  const url = '/api/search-collections'
  const { data } = await withStrapiAxios<ISearchCollectionsResponse>({ url })

  return data
}

export const useGetSearchCollections = () => {
  return useQuery(['getSearchCollections'], () => getSearchCollections(), {
    refetchOnWindowFocus: false,
    suspense: true,
    useErrorBoundary: true,
    select: data => searchCollectionsTransformer(data),
  })
}
