import { Space } from '@mantine/core'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Suspense, useCallback, useMemo } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { CommonErrorFallback, PriceLabel, UnSupportedNFTNotice } from '@/components/common'
import { ToolTip } from '@/components/core-ui'
import { VerifiedBadge } from '@/shared/assets'
import { ROUTE_PATH } from '@/shared/constants'
import { SEARCH_HOME_LIST_ITEM_THEME, SEARCH_RESULT_TAB_KEY } from '@/shared/constants/search'
import { useBalance } from '@/shared/hooks'
import { useGetSearchResultItemList } from '@/shared/services/search'
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks'
import { selectSearchText, setCurrentTab } from '@/shared/store/search'
import { IAllCoinPrice } from '@/shared/types'
import { ISearchResultItemListRequest, ISearchResultItemListResponse } from '@/shared/types/search'
import { convertBalanceToCurrencyWithSymbol } from '@/shared/utils'

import NoResult from '../../NoResult'
import SearchSectionTitle from '../../SearchHomeContent/SearchSectionTitle'
import SearchHomeListItem from '../../SearchHomeListItem'

interface ISearchResultItemDesignProps {
  data?: ISearchResultItemListResponse
  currency: {
    icon: '₩' | '$'
    name: 'KRW' | 'USD'
  }
  coinPrice?: IAllCoinPrice
  handleCurrentTab: () => void
  handleClick: (collectionAddress: string, tokenId: string, isVerified: boolean) => void
}

const SearchResultItem = () => {
  const { push } = useRouter()
  const dispatch = useAppDispatch()
  const searchText = useAppSelector(selectSearchText)
  const { allCoinPrice: coinPrice, currency } = useBalance()

  const handleCurrentTab = useCallback(() => {
    dispatch(setCurrentTab(SEARCH_RESULT_TAB_KEY.item))
  }, [dispatch])

  const params: ISearchResultItemListRequest | null = useMemo(() => {
    if (searchText === '') return null

    return {
      searchText,
      page: 1,
      perPage: 5,
      order: 'LISTING',
      orderBy: 'DESC',
    }
  }, [searchText])

  const { data } = useGetSearchResultItemList(params)

  const handleClick = useCallback(
    (collectionAddress: string, tokenId: string, isVerified: boolean) => {
      if (!isVerified) return

      push(
        ROUTE_PATH.ITEM_DETAIL.replace(':collectionAddress', collectionAddress).replace(
          ':tokenId',
          tokenId,
        ),
      )
    },
    [push],
  )

  return (
    <SearchResultItem.Design
      data={data}
      currency={currency}
      coinPrice={coinPrice}
      handleClick={handleClick}
      handleCurrentTab={handleCurrentTab}
    />
  )
}

const SearchResultItemDesign = ({
  data,
  currency,
  coinPrice,
  handleClick,
  handleCurrentTab,
}: ISearchResultItemDesignProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  if (data === undefined || (data && data.items.length === 0))
    return (
      <section className="px-4">
        <SearchSectionTitle
          title={t('common:common.label-item')}
          moreText={t('common:common.cta-label-view-all')}
          moreTextHandler={handleCurrentTab}
        />
        <Space h={16} />
        <NoResult bgColor="secondary" />
      </section>
    )

  const { items } = data
  const lastIndex = items.length - 1

  return (
    <section className="px-4">
      <SearchSectionTitle
        title={t('common:common.label-item')}
        moreText={t('common:common.cta-label-view-all')}
        moreTextHandler={handleCurrentTab}
      />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {items.map((item, index) => {
          const isShowUnSupportedNFT = !item.isVerified

          const currencyCoinPrice = convertBalanceToCurrencyWithSymbol({
            currency,
            coinPrice,
            paymentType: item.paymentType,
            price: (item.salePrice && item.salePrice.toString()) ?? '0',
          })

          return (
            <SearchHomeListItem
              theme={SEARCH_HOME_LIST_ITEM_THEME.threeLineSection}
              key={`search-result-all-tab-item-${item.itemTitle}`}
              content={{
                title: item.itemTitle,
                subTitle: item.collectionTitle,
                description: (
                  <>
                    {isShowUnSupportedNFT ? (
                      <UnSupportedNFTNotice />
                    ) : (
                      <div className="flex flex-col">
                        <p className="text-base font-semibold leading-[20px] text-appTextPrimary">
                          {t('common:common.label-abt')} {currencyCoinPrice}
                        </p>
                        <PriceLabel
                          size="xs"
                          isShowPaymentType
                          paymentType={item.paymentType}
                          priceTextStyle="text-appTextTertiary"
                          price={parseFloat(item.salePrice ?? '00')}
                        />
                      </div>
                    )}
                  </>
                ),
                image: item.image,
                Badge: item.isOriginals
                  ? () => (
                      <ToolTip
                        text={t('common:common.label-konkrit-originals')}
                        direction="bottom"
                        arrowSize={12}>
                        <VerifiedBadge />
                      </ToolTip>
                    )
                  : undefined,
                handleClick: () => {
                  handleClick(item.collectionAddress, item.tokenId, item.isVerified)
                },
              }}
              isLast={index === lastIndex}
            />
          )
        })}
      </div>
    </section>
  )
}

const SearchResultItemSkeleton = () => {
  return (
    <section className="px-4">
      <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {range(5).map(index => (
          <SearchHomeListItem.Skeleton key={`search-result-all-tab-item-skeleton-${index}`} />
        ))}
      </div>
    </section>
  )
}

const SearchResultItemErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  const dispatch = useAppDispatch()

  const handleCurrentTab = useCallback(() => {
    dispatch(setCurrentTab(SEARCH_RESULT_TAB_KEY.item))
  }, [dispatch])

  return (
    <section className="px-4">
      <SearchSectionTitle
        title={t('common:common.label-item')}
        moreText={t('common:common.cta-label-view-all')}
        moreTextHandler={handleCurrentTab}
      />
      <Space h={24} />
      <CommonErrorFallback
        error={error}
        bgColor="bg-appBgSecondary"
        handleRetry={resetErrorBoundary}
        title={t('common:error-data')}
      />
    </section>
  )
}

const SearchResultWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary FallbackComponent={SearchResultItem.ErrorFallback} onReset={reset}>
      <Suspense fallback={<SearchResultItem.Skeleton />}>
        <SearchResultItem />
      </Suspense>
    </ErrorBoundary>
  )
}

export default SearchResultItem

SearchResultItem.Design = SearchResultItemDesign
SearchResultItem.Skeleton = SearchResultItemSkeleton
SearchResultItem.ErrorFallback = SearchResultItemErrorFallback
SearchResultItem.WithErrorBoundary = SearchResultWithErrorBoundary
