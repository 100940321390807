/* eslint-disable @next/next/no-img-element */
import { CHAIN_ICON_MAPPINGS } from '@/shared/constants'

interface IChainWithCategoryTag {
  chainId: string
  categoryTitle: string
}

const ChainWithCategoryTag = ({ chainId, categoryTitle }: IChainWithCategoryTag) => {
  return (
    <div className="flex items-center gap-2">
      <div className="rounded-sm bg-appBgSecondary px-2 py-1">
        <p className="font-medium text-xs leading-4 text-appTextAccent">{categoryTitle}</p>
      </div>

      <div className="w-[1px] h-[15px] bg-appIconQuaternary" />

      <div className="flex items-center gap-1">
        {chainId && (
          <img src={CHAIN_ICON_MAPPINGS[chainId].img} alt={CHAIN_ICON_MAPPINGS[chainId].name} />
        )}
        <p className="text-xs leading-4 font-medium text-appTextTertiary">
          {CHAIN_ICON_MAPPINGS[chainId].name}
        </p>
      </div>
    </div>
  )
}

export default ChainWithCategoryTag
