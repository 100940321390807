import { Space } from '@mantine/core'

import AllCollections from './AllCollections'
import Onboardings from './Onboardings'
import SuggestedKeywords from './SuggestedKeywords'

const SearchHome = () => {
  return (
    <>
      <SuggestedKeywords.WithErrorBoundary />
      <AllCollections.WithErrorBoundary />
      <Space h={60} />
      <Onboardings.WithErrorBoundary />
      <Space h={60} />
    </>
  )
}

export default SearchHome
