import { useTranslation } from 'react-i18next'

import { cx } from '@/shared/utils'

const NoResult = ({
  wrapperClassName,
  bgColor = 'transparent',
}: {
  wrapperClassName?: string
  bgColor?: 'secondary' | 'transparent'
}) => {
  const { t } = useTranslation('common')
  return (
    <div
      className={cx(
        'py-16 px-[105px] text-textSecondary rounded-md',
        bgColor === 'secondary' ? 'bg-appBgSecondary' : 'bg-transparent',
        wrapperClassName,
      )}>
      <p className="text-base text-appTextTertiary font-normal leading-[21px] text-center">
        {t('common.empty-search-result')}
      </p>
    </div>
  )
}

export default NoResult
