import { Space } from '@mantine/core'
import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryErrorResetBoundary,
} from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Fragment, Suspense, useCallback, useMemo, useRef } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { CommonErrorFallback } from '@/components/common'
import { RefreshButton } from '@/shared/assets'
import { ROUTE_PATH } from '@/shared/constants'
import { useIntersectionObserver } from '@/shared/hooks'
import { useGetSearchResultOriginalsListInfinite } from '@/shared/services/search'
import { useAppSelector } from '@/shared/store/hooks'
import { selectSearchText } from '@/shared/store/search'
import {
  ISearchResultOriginalsListRequest,
  ISearchResultOriginalsListResponse,
} from '@/shared/types/search'

import NoResult from '../NoResult'
import OriginalsItem from '../OriginalsItem'

interface ISearchResultOriginalsDesignProps {
  hasNextPage?: boolean
  targetRef: React.RefObject<HTMLDivElement>
  data?: InfiniteData<ISearchResultOriginalsListResponse>
  handleClick: (originalsId: string) => void
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<InfiniteData<ISearchResultOriginalsListResponse>, unknown>>
}

const SearchResultOriginalsTabContent = () => {
  const { push } = useRouter()
  const targetRef = useRef<HTMLDivElement>(null)
  const searchText = useAppSelector(selectSearchText)

  const params: ISearchResultOriginalsListRequest | null = useMemo(() => {
    if (searchText === '') return null

    return {
      searchText,
      orderBy: 'DESC',
      order: 'CREATED_AT',
    }
  }, [searchText])

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useGetSearchResultOriginalsListInfinite(params)

  useIntersectionObserver({
    onIntersect: () => {
      !isFetchingNextPage && hasNextPage && fetchNextPage()
    },
    targetRef,
  })

  const handleClick = useCallback(
    (originalsId: string) => {
      push(ROUTE_PATH.ORIGINALS_BRAND_PAGE.replace(':originalsId', originalsId))
    },
    [push],
  )

  return (
    <SearchResultOriginalsTabContent.Design
      data={data}
      refetch={refetch}
      targetRef={targetRef}
      hasNextPage={hasNextPage}
      handleClick={handleClick}
    />
  )
}

const SearchResultOriginalsTabContentDesign = ({
  data,
  refetch,
  targetRef,
  hasNextPage,
  handleClick,
}: ISearchResultOriginalsDesignProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  if (data === undefined || data.pages[0].pagination.totalCount === 0) return <NoResult />

  return (
    <>
      <div className="px-4">
        <div className="flex items-center gap-x-1">
          <RefreshButton
            size="sm"
            theme="white"
            onClick={() => {
              refetch()
              toast(t('common:common.update-requested'))
            }}
          />
          <p className="text-sm font-medium text-appTextPrimary leading-[18px]">
            {t('common:common.label-originals-number').replace(
              '{value}',
              data.pages[0].pagination.totalCount.toString() ?? '0',
            )}
          </p>
        </div>

        <Space h={24} />

        <div className="flex flex-col gap-4">
          {data.pages.map(({ originals }, index) => {
            return (
              <Fragment key={`search-result-originals-tab-content-${index}`}>
                {originals.map(original => {
                  return (
                    <OriginalsItem
                      image={original.image}
                      handleClick={handleClick}
                      chainId={original.chainId}
                      originalsId={original.originalsId}
                      categoryTitle={original.categoryTitle}
                      originalsTitle={original.originalsTitle}
                      key={`search-result-originals-tab-${original.originalsId}`}
                    />
                  )
                })}
              </Fragment>
            )
          })}

          {hasNextPage && (
            <div className="flex flex-col gap-5" ref={targetRef}>
              {range(3).map(index => (
                <OriginalsItem.Skeleton
                  key={`search-result-originals-tab-content-skeleton-${index}`}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const SearchResultOriginalsTabContentSkeleton = () => {
  return (
    <section className="px-4">
      <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={24} />
      <div className="flex flex-col gap-5">
        {range(3).map(index => (
          <OriginalsItem.Skeleton key={`search-result-originals-tab-content-skeleton-${index}`} />
        ))}
      </div>
    </section>
  )
}

const SearchResultOriginalsTabContentErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-search'])

  return (
    <>
      <Space h={24} />
      <section className="px-4">
        <CommonErrorFallback
          error={error}
          bgColor="bg-appBgSecondary"
          handleRetry={resetErrorBoundary}
          title={t('common:common:error-data-items')}
        />
      </section>
    </>
  )
}

const SearchResultWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary
      FallbackComponent={SearchResultOriginalsTabContent.ErrorFallback}
      onReset={reset}>
      <Suspense fallback={<SearchResultOriginalsTabContent.Skeleton />}>
        <SearchResultOriginalsTabContent />
      </Suspense>
    </ErrorBoundary>
  )
}

export default SearchResultOriginalsTabContent

SearchResultOriginalsTabContent.Design = SearchResultOriginalsTabContentDesign
SearchResultOriginalsTabContent.WithErrorBoundary = SearchResultWithErrorBoundary
SearchResultOriginalsTabContent.Skeleton = SearchResultOriginalsTabContentSkeleton
SearchResultOriginalsTabContent.ErrorFallback = SearchResultOriginalsTabContentErrorFallback
