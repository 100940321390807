import { Space } from '@mantine/core'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { TabBar } from '@/components/common'
import { TAB_BAR_THEME } from '@/shared/constants'
import { SEARCH_RESULT_TAB, SEARCH_RESULT_TAB_KEY } from '@/shared/constants/search'
import { useAppSelector } from '@/shared/store/hooks'
import { selectCurrentTab, setCurrentTab } from '@/shared/store/search'

import SearchResultAllTabContent from './SearchResultAllTabContent'
import SearchResultCollectionTabContent from './SearchResultCollectionTabContent'
import SearchResultItemTabContent from './SearchResultItemTabContent'
import SearchResultOriginalsTabContent from './SearchResultOriginalsTabContent'

const SearchResult = () => {
  const dispatch = useDispatch()
  const currentTab = useAppSelector(selectCurrentTab)
  const { t } = useTranslation()

  const getTabName = useCallback(
    (key: keyof typeof SEARCH_RESULT_TAB_KEY) => {
      switch (key) {
        case SEARCH_RESULT_TAB_KEY.all:
          return t('common.label-all')
        case SEARCH_RESULT_TAB_KEY.originals:
          return t('common.label-originals')
        case SEARCH_RESULT_TAB_KEY.item:
          return t('common.label-item')
        case SEARCH_RESULT_TAB_KEY.collection:
          return t('common.label-collection')
      }
    },
    [t],
  )

  const tabList = useMemo(() => {
    const createTabList = SEARCH_RESULT_TAB.map(tab => ({
      key: tab.key,
      name: getTabName(tab.key),
      handler: () => dispatch(setCurrentTab(tab.key)),
    }))

    return createTabList
  }, [dispatch, getTabName])

  const tabContentMappings = {
    [SEARCH_RESULT_TAB_KEY.all]: <SearchResultAllTabContent />,
    [SEARCH_RESULT_TAB_KEY.item]: <SearchResultItemTabContent.WithErrorBoundary />,
    [SEARCH_RESULT_TAB_KEY.collection]: <SearchResultCollectionTabContent.WithErrorBoundary />,
    [SEARCH_RESULT_TAB_KEY.originals]: <SearchResultOriginalsTabContent.WithErrorBoundary />,
  }

  return (
    <>
      <div className="sticky mt-[72px] top-0 left-0 right-0 bg-bgPrimary">
        <TabBar
          tabData={tabList}
          selectedTab={currentTab}
          wrapperClassNames="!mb-0 px-4"
          theme={TAB_BAR_THEME.handler}
        />
      </div>
      <Space h={16} />
      <div>{tabContentMappings[currentTab]}</div>
    </>
  )
}

export default SearchResult
