/* eslint-disable @next/next/no-img-element */
import { Space } from '@mantine/core'
import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryErrorResetBoundary,
} from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Fragment, Suspense, useCallback, useMemo, useRef } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { CommonErrorFallback } from '@/components/common'
import { ToolTip } from '@/components/core-ui'
import { RefreshButton, VerifiedBadge } from '@/shared/assets'
import { ROUTE_PATH } from '@/shared/constants'
import { SEARCH_HOME_LIST_ITEM_THEME } from '@/shared/constants/search'
import { useIntersectionObserver } from '@/shared/hooks'
import { useGetSearchResultCollectionListInfinite } from '@/shared/services/search'
import { useAppSelector } from '@/shared/store/hooks'
import { selectSearchText } from '@/shared/store/search'
import {
  ISearchResultCollectionListRequest,
  ISearchResultCollectionListResponse,
} from '@/shared/types/search'

import ChainWithCategoryTag from '../ChainWithCategoryTag'
import NoResult from '../NoResult'
import SearchHomeListItem from '../SearchHomeListItem'

interface ISearchResultCollectionDesignProps {
  hasNextPage?: boolean
  targetRef: React.RefObject<HTMLDivElement>
  data?: InfiniteData<ISearchResultCollectionListResponse>
  handleClick: (collectionAddress: string) => void
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<InfiniteData<ISearchResultCollectionListResponse>, unknown>>
}

const SearchResultCollectionTabContent = () => {
  const { push } = useRouter()
  const targetRef = useRef<HTMLDivElement>(null)
  const searchText = useAppSelector(selectSearchText)

  const params: ISearchResultCollectionListRequest | null = useMemo(() => {
    if (searchText === '') return null

    return {
      searchText,
      orderBy: 'DESC',
      order: 'CREATED_AT',
    }
  }, [searchText])

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useGetSearchResultCollectionListInfinite(params)

  useIntersectionObserver({
    onIntersect: () => {
      !isFetchingNextPage && hasNextPage && fetchNextPage()
    },
    targetRef,
  })

  const handleClick = useCallback(
    (collectionAddress: string) => {
      push(ROUTE_PATH.COLLECTION_DETAIL.replace(':collectionAddress', collectionAddress))
    },
    [push],
  )

  return (
    <SearchResultCollectionTabContent.Design
      data={data}
      refetch={refetch}
      targetRef={targetRef}
      hasNextPage={hasNextPage}
      handleClick={handleClick}
    />
  )
}

const SearchResultCollectionTabContentDesign = ({
  data,
  refetch,
  targetRef,
  hasNextPage,
  handleClick,
}: ISearchResultCollectionDesignProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  if (data === undefined || data.pages[0].pagination.totalCount === 0) return <NoResult />

  return (
    <section className="px-4">
      <div className="flex items-center gap-x-1">
        <RefreshButton
          size="sm"
          theme="white"
          onClick={() => {
            refetch()
            toast(t('common:common.update-requested'))
          }}
        />
        <p className="text-sm font-medium text-appTextPrimary leading-[18px]">
          {t('common:common.label-collection-number').replace(
            '{value}',
            data.pages[0].pagination.totalCount.toString() ?? '0',
          )}
        </p>
      </div>

      <Space h={24} />

      <div className="flex flex-col gap-4">
        {data.pages.map(({ collections }, index) => {
          return (
            <Fragment key={`search-result-item-tab-content-${index}`}>
              {collections.map((collection, index) => {
                const lastIndex = collections.length - 1

                return (
                  <SearchHomeListItem
                    theme={SEARCH_HOME_LIST_ITEM_THEME.twoLineSection}
                    key={`search-result-item-tab-${collection.collectionAddress}`}
                    content={{
                      title: collection.collectionTitle,
                      description: (
                        <ChainWithCategoryTag
                          chainId={collection.chainId}
                          categoryTitle={collection.categoryTitle}
                        />
                      ),
                      image: collection.image,
                      Badge: collection.isOriginals
                        ? () => (
                            <ToolTip
                              text={t('common:common:label-konkrit-originals')}
                              direction="bottom"
                              arrowSize={12}>
                              <VerifiedBadge />
                            </ToolTip>
                          )
                        : undefined,
                      handleClick: () => {
                        handleClick(collection.collectionAddress)
                      },
                    }}
                    descriptionWrapperClass="text-appTextSecondary"
                    isLast={index === lastIndex}
                  />
                )
              })}
            </Fragment>
          )
        })}

        {hasNextPage && (
          <div ref={targetRef} className="flex flex-col gap-4">
            {range(5).map(index => (
              <SearchHomeListItem.Skeleton
                key={`search-result-collection-tab-content-skeleton-${index}`}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

const SearchResultCollectionTabContentSkeleton = () => {
  return (
    <section className="px-4">
      <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {range(5).map(index => (
          <SearchHomeListItem.Skeleton
            key={`search-result-collection-tab-content-skeleton-${index}`}
          />
        ))}
      </div>
    </section>
  )
}

const SearchResultCollectionTabContentErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  return (
    <>
      <Space h={24} />
      <section className="px-4">
        <CommonErrorFallback
          error={error}
          bgColor="bg-appBgSecondary"
          handleRetry={resetErrorBoundary}
          title={t('common:common.error-data')}
        />
      </section>
    </>
  )
}

const SearchResultWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary
      onReset={reset}
      FallbackComponent={SearchResultCollectionTabContent.ErrorFallback}>
      <Suspense fallback={<SearchResultCollectionTabContent.Skeleton />}>
        <SearchResultCollectionTabContent />
      </Suspense>
    </ErrorBoundary>
  )
}

export default SearchResultCollectionTabContent

SearchResultCollectionTabContent.Design = SearchResultCollectionTabContentDesign
SearchResultCollectionTabContent.WithErrorBoundary = SearchResultWithErrorBoundary
SearchResultCollectionTabContent.Skeleton = SearchResultCollectionTabContentSkeleton
SearchResultCollectionTabContent.ErrorFallback = SearchResultCollectionTabContentErrorFallback
