import { Space } from '@mantine/core'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Suspense, useCallback, useMemo } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { CommonErrorFallback } from '@/components/common'
import { ToolTip } from '@/components/core-ui'
import { VerifiedBadge } from '@/shared/assets'
import { ROUTE_PATH } from '@/shared/constants'
import { SEARCH_HOME_LIST_ITEM_THEME, SEARCH_RESULT_TAB_KEY } from '@/shared/constants/search'
import { useGetSearchResultCollectionList } from '@/shared/services/search'
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks'
import { selectSearchText, setCurrentTab } from '@/shared/store/search'
import {
  ISearchResultCollectionListRequest,
  ISearchResultCollectionListResponse,
} from '@/shared/types/search'

import ChainWithCategoryTag from '../../ChainWithCategoryTag'
import NoResult from '../../NoResult'
import SearchSectionTitle from '../../SearchHomeContent/SearchSectionTitle'
import SearchHomeListItem from '../../SearchHomeListItem'

interface ISearchResultCollectionDesignProps {
  data?: ISearchResultCollectionListResponse
  handleCurrentTab: () => void
  handleClick: (collectionAddress: string) => void
}

const SearchResultCollection = () => {
  const { push } = useRouter()
  const dispatch = useAppDispatch()
  const searchText = useAppSelector(selectSearchText)

  const handleCurrentTab = useCallback(() => {
    dispatch(setCurrentTab(SEARCH_RESULT_TAB_KEY.collection))
  }, [dispatch])

  const params: ISearchResultCollectionListRequest | null = useMemo(() => {
    if (searchText === '') return null
    return {
      searchText,
      page: 1,
      perPage: 5,
      order: 'CREATED_AT',
      orderBy: 'DESC',
    }
  }, [searchText])
  const { data } = useGetSearchResultCollectionList(params)

  const handleClick = useCallback(
    (collectionAddress: string) => {
      push(ROUTE_PATH.COLLECTION_DETAIL.replace(':collectionAddress', collectionAddress))
    },
    [push],
  )

  return (
    <SearchResultCollection.Design
      data={data}
      handleClick={handleClick}
      handleCurrentTab={handleCurrentTab}
    />
  )
}

const SearchResultCollectionDesign = ({
  data,
  handleClick,
  handleCurrentTab,
}: ISearchResultCollectionDesignProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  if (data === undefined || (data && data.collections.length === 0))
    return (
      <section className="px-4 py-6">
        <SearchSectionTitle
          title={t('common:common.label-collection')}
          moreText={t('common:common.cta-label-view-all')}
          moreTextHandler={handleCurrentTab}
        />
        <Space h={16} />
        <NoResult bgColor="secondary" />
      </section>
    )

  const { collections } = data
  const lastIndex = collections.length - 1

  return (
    <section className="px-4">
      <SearchSectionTitle
        title={t('common:common.label-collection')}
        moreText={t('common:common.cta-label-view-all')}
        moreTextHandler={handleCurrentTab}
      />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {collections.map((collection, index) => (
          <SearchHomeListItem
            theme={SEARCH_HOME_LIST_ITEM_THEME.twoLineSection}
            key={`search-result-all-tab-collection-${collection.collectionTitle}`}
            content={{
              title: collection.collectionTitle,
              description: (
                <ChainWithCategoryTag
                  chainId={collection.chainId}
                  categoryTitle={collection.categoryTitle}
                />
              ),
              image: collection.image,
              Badge: collection.isOriginals
                ? () => (
                    <ToolTip
                      text={t('common:common.label-konkrit-originals')}
                      direction="bottom"
                      arrowSize={12}>
                      <VerifiedBadge />
                    </ToolTip>
                  )
                : undefined,
              handleClick: () => {
                handleClick(collection.collectionAddress)
              },
            }}
            descriptionWrapperClass="text-appTextTertiary"
            isLast={index === lastIndex}
          />
        ))}
      </div>
    </section>
  )
}

const SearchResultCollectionSkeleton = () => {
  return (
    <section className="px-4">
      <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {range(5).map(index => (
          <SearchHomeListItem.Skeleton key={`search-result-all-tab-collection-skeleton-${index}`} />
        ))}
      </div>
    </section>
  )
}

const SearchResultCollectionErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  const dispatch = useAppDispatch()

  const handleCurrentTab = useCallback(() => {
    dispatch(setCurrentTab(SEARCH_RESULT_TAB_KEY.collection))
  }, [dispatch])

  return (
    <section className="px-4">
      <SearchSectionTitle
        title={t('common:common.label-collection')}
        moreText={t('common:common.cta-label-view-all')}
        moreTextHandler={handleCurrentTab}
      />
      <Space h={24} />
      <CommonErrorFallback
        error={error}
        bgColor="bg-appBgSecondary"
        handleRetry={resetErrorBoundary}
        title={t('common:error-data')}
      />
    </section>
  )
}

const SearchResultWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary FallbackComponent={SearchResultCollection.ErrorFallback} onReset={reset}>
      <Suspense fallback={<SearchResultCollection.Skeleton />}>
        <SearchResultCollection />
      </Suspense>
    </ErrorBoundary>
  )
}

export default SearchResultCollection

SearchResultCollection.Design = SearchResultCollectionDesign
SearchResultCollection.Skeleton = SearchResultCollectionSkeleton
SearchResultCollection.WithErrorBoundary = SearchResultWithErrorBoundary
SearchResultCollection.ErrorFallback = SearchResultCollectionErrorFallback
