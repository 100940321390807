import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import {
  ISearchResultCollectionListRequest,
  ISearchResultCollectionListResponse,
} from '@/shared/types/search'

import withAxios from '../api'

const PER_PAGE = 20

export const getSearchResultCollectionList = async (
  params: ISearchResultCollectionListRequest | null,
) => {
  const url = '/api/v1/public/search/collections'
  const { data } = await withAxios<ISearchResultCollectionListResponse>({
    url,
    params,
  })

  return data
}

export const useGetSearchResultCollectionList = (
  params: ISearchResultCollectionListRequest | null,
) =>
  useQuery(
    ['getSearchResultCollectionList', { params }],
    () => getSearchResultCollectionList(params),
    {
      enabled: params !== null,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )

export const useGetSearchResultCollectionListInfinite = (
  params: ISearchResultCollectionListRequest | null,
) =>
  useInfiniteQuery<ISearchResultCollectionListResponse>(
    ['getSearchResultCollectionListInfinite', { params }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...params,
        page: pageParam,
        perPage: PER_PAGE,
      } as ISearchResultCollectionListRequest
      const data = await getSearchResultCollectionList(queryParams)
      return data
    },
    {
      enabled: params !== null,
      getNextPageParam: data =>
        (data?.collections?.length === PER_PAGE && data.pagination.page + 1) ?? undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
