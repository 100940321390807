import { useQuery } from '@tanstack/react-query'

import { IPurchaseGuideType } from '@/shared/types'

import { withStrapiAxios } from '../api'

export const getPurchaseGuide = async ({ originalsId }: { originalsId?: string }) => {
  const url = `/api/purchase-guide/${originalsId}`
  const { data } = await withStrapiAxios<IPurchaseGuideType>({ url })

  return data
}

export const useGetPurchaseGuide = (pathname: string, originalsId?: string) => {
  return useQuery(
    [pathname, 'getPurchaseGuide', { originalsId }],
    () => getPurchaseGuide({ originalsId }),
    {
      enabled: originalsId !== undefined,
    },
  )
}
