import { useRouter } from 'next/router'

import { cx } from '@/shared/utils'

const SearchSectionTitle = ({
  title,
  moreText,
  moreTextLink,
  moreTextHandler,
  position = 'left',
}: {
  title: string
  moreTextLink?: string
  moreText?: string
  moreTextHandler?: () => void
  position?: 'left' | 'right'
}) => {
  const { push } = useRouter()
  const useMoreText =
    moreText !== undefined && (moreTextLink !== undefined || moreTextHandler !== undefined)

  if (!useMoreText)
    return (
      <h1 className={cx('font-semibold text-appTextPrimary text-2xl', `text-${position}`)}>
        {title}
      </h1>
    )

  return (
    <div className="flex justify-between items-center">
      <h1 className="font-semibold text-appTextPrimary text-2xl leading-[30px]">{title}</h1>
      <div
        onClick={() => {
          if (moreTextHandler !== undefined) return moreTextHandler()
          if (moreTextLink !== undefined) return push(moreTextLink)
        }}
        className="text-sm px-3 py-2 leading-[18px] text-appTextTertiary min-w-[100px] text-right">
        {moreText ?? '전체보기'}
      </div>
    </div>
  )
}

export default SearchSectionTitle
