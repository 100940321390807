import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { ISearchResultItemListRequest, ISearchResultItemListResponse } from '@/shared/types/search'

import withAxios from '../api'

const PER_PAGE = 20

export const getSearchResultItemList = async (params: ISearchResultItemListRequest | null) => {
  const url = '/api/v1/public/search/items'
  const { data } = await withAxios<ISearchResultItemListResponse>({
    url,
    params,
  })

  return data
}

export const useGetSearchResultItemList = (params: ISearchResultItemListRequest | null) =>
  useQuery(['getSearchResultItemList', { params }], () => getSearchResultItemList(params), {
    enabled: params !== null,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    suspense: true,
    useErrorBoundary: true,
  })

export const useGetSearchResultItemListInfinite = (params: ISearchResultItemListRequest | null) =>
  useInfiniteQuery<ISearchResultItemListResponse>(
    ['getSearchResultItemListInfinite', { params }],
    async ({ pageParam = 1 }) => {
      const queryParams = {
        ...params,
        page: pageParam,
        perPage: PER_PAGE,
      } as ISearchResultItemListRequest
      const data = await getSearchResultItemList(queryParams)
      return data
    },
    {
      enabled: params !== null,
      getNextPageParam: data =>
        (data?.items?.length === PER_PAGE && data.pagination.page + 1) ?? undefined,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      suspense: true,
      useErrorBoundary: true,
    },
  )
