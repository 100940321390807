import { useQuery } from '@tanstack/react-query'

import { IOriginalsHomeBenefitsType, IStrapiArrayBaseResponse } from '@/shared/types'

import { withStrapiAxios } from '../api'
import getOriginalsHomeHighlightItems from './getOriginalsHomeHighlightItems'

export const getOriginalsHomeBenefitList = async ({
  originalsIds,
}: {
  originalsIds?: string[]
}) => {
  const url = '/api/benefits'

  const {
    data: { data },
  } = await withStrapiAxios<IStrapiArrayBaseResponse<IOriginalsHomeBenefitsType>>({
    url,
    method: 'POST',
    data: {
      originalsIds,
    },
  })

  return data
}

export const useGetOriginalsHomeBenefitList = (pathname: string, originalsIds?: string[]) => {
  return useQuery(
    [`getOriginalsHomeBenefitList-${pathname}`, { originalsIds }],
    () => getOriginalsHomeBenefitList({ originalsIds }),
    {
      enabled: originalsIds !== undefined,
      refetchOnWindowFocus: false,
    },
  )
}

export default getOriginalsHomeHighlightItems
