import { useQuery } from '@tanstack/react-query'

import { IOriginalsHomeHighLightItemType, IStrapiBaseResponse } from '@/shared/types'

import { withStrapiAxios } from '../api'

export const getOriginalsHomeHighlightItems = async () => {
  const url = '/api/originals-highlights'
  const {
    data: {
      data: {
        attributes: { highlight },
      },
    },
  } = await withStrapiAxios<IStrapiBaseResponse<IOriginalsHomeHighLightItemType>>({ url })

  return highlight
}

export const useGetOriginalsHomeHighlightItems = (pathname: string) => {
  return useQuery(
    [`getOriginalsHomeHighlightItems-${pathname}`],
    () => getOriginalsHomeHighlightItems(),
    {
      suspense: true,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  )
}

export default getOriginalsHomeHighlightItems
