import { Space } from '@mantine/core'
import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryErrorResetBoundary,
} from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Fragment, Suspense, useCallback, useMemo, useRef } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { CommonErrorFallback, PriceLabel, UnSupportedNFTNotice } from '@/components/common'
import { ToolTip } from '@/components/core-ui'
import { RefreshButton, VerifiedBadge } from '@/shared/assets'
import { ROUTE_PATH } from '@/shared/constants'
import { SEARCH_HOME_LIST_ITEM_THEME } from '@/shared/constants/search'
import { useBalance, useIntersectionObserver } from '@/shared/hooks'
import { useGetSearchResultItemListInfinite } from '@/shared/services/search'
import { useAppSelector } from '@/shared/store/hooks'
import { selectSearchText } from '@/shared/store/search'
import { IAllCoinPrice } from '@/shared/types'
import { ISearchResultItemListRequest, ISearchResultItemListResponse } from '@/shared/types/search'
import { convertBalanceToCurrencyWithSymbol } from '@/shared/utils'

import NoResult from '../NoResult'
import SearchHomeListItem from '../SearchHomeListItem'

interface ISearchResultItemDesignProps {
  data?: InfiniteData<ISearchResultItemListResponse>
  hasNextPage?: boolean
  targetRef: React.RefObject<HTMLDivElement>
  currency: {
    icon: '₩' | '$'
    name: 'KRW' | 'USD'
  }
  coinPrice?: IAllCoinPrice
  handleClick: (collectionAddress: string, tokenId: string, isVerified: boolean) => void
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<InfiniteData<ISearchResultItemListResponse>, unknown>>
}

const SearchResultItemTabContent = () => {
  const { push } = useRouter()
  const targetRef = useRef<HTMLDivElement>(null)
  const searchText = useAppSelector(selectSearchText)
  const { allCoinPrice: coinPrice, currency } = useBalance()
  const params: ISearchResultItemListRequest | null = useMemo(() => {
    if (searchText === '') return null
    return {
      searchText,
      orderBy: 'DESC',
      order: 'LISTING',
    }
  }, [searchText])

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useGetSearchResultItemListInfinite(params)

  useIntersectionObserver({
    onIntersect: () => {
      !isFetchingNextPage && hasNextPage && fetchNextPage()
    },
    targetRef,
  })

  const handleClick = useCallback(
    (collectionAddress: string, tokenId: string, isVerified: boolean) => {
      if (!isVerified) return

      push(
        ROUTE_PATH.ITEM_DETAIL.replace(':collectionAddress', collectionAddress).replace(
          ':tokenId',
          tokenId,
        ),
      )
    },
    [push],
  )

  return (
    <SearchResultItemTabContent.Design
      data={data}
      refetch={refetch}
      currency={currency}
      coinPrice={coinPrice}
      targetRef={targetRef}
      hasNextPage={hasNextPage}
      handleClick={handleClick}
    />
  )
}

const SearchResultItemTabContentDesign = ({
  data,
  refetch,
  currency,
  coinPrice,
  targetRef,
  hasNextPage,
  handleClick,
}: ISearchResultItemDesignProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  if (data === undefined || data.pages[0].pagination.totalCount === 0) return <NoResult />

  return (
    <section className="px-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-x-1">
          <RefreshButton
            size="sm"
            theme="white"
            onClick={() => {
              refetch()
              toast(t('common:common.update-requested'))
            }}
          />
          <p className="text-sm font-medium text-appTextPrimary leading-[18px]">
            {t('common:common.label-item-number').replace(
              '{value}',
              data.pages[0].pagination.totalCount.toString() ?? '0',
            )}
          </p>
        </div>
        <p className="text-sm font-medium text-appTextPrimary leading-[18px]">
          {t('common:common.label-listing-recent-sales')}
        </p>
      </div>

      <Space h={24} />

      <div className="flex flex-col gap-4">
        {data.pages.map(({ items }, index) => {
          return (
            <Fragment key={`search-result-item-tab-content-${index}`}>
              {items.map((item, index) => {
                const lastIndex = items.length - 1
                const isShowUnSupportedNFT = !item.isVerified
                const currencyCoinPrice = convertBalanceToCurrencyWithSymbol({
                  currency,
                  coinPrice,
                  paymentType: item.paymentType,
                  price: (item.salePrice && item.salePrice.toString()) ?? '0',
                })

                return (
                  <SearchHomeListItem
                    theme={SEARCH_HOME_LIST_ITEM_THEME.threeLineSection}
                    key={`search-result-item-tab-${item.collectionAddress}-${item.tokenId}`}
                    content={{
                      title: item.itemTitle,
                      subTitle: item.collectionTitle,
                      description: (
                        <>
                          {isShowUnSupportedNFT ? (
                            <UnSupportedNFTNotice />
                          ) : (
                            <div className="flex flex-col gap-1">
                              <p className="text-base font-semibold leading-[20px] text-appTextPrimary">
                                {t('common:common.label-abt')} {currencyCoinPrice}
                              </p>
                              <PriceLabel
                                size="xs"
                                isShowPaymentType
                                paymentType={item.paymentType}
                                priceTextStyle="text-appTextTertiary"
                                price={parseFloat(item.salePrice ?? '00')}
                              />
                            </div>
                          )}
                        </>
                      ),
                      image: item.image,
                      Badge: item.isOriginals
                        ? () => (
                            <ToolTip
                              text={t('common:common:label-konkrit-originals')}
                              direction="bottom"
                              arrowSize={12}>
                              <VerifiedBadge />
                            </ToolTip>
                          )
                        : undefined,
                      handleClick: () => {
                        handleClick(item.collectionAddress, item.tokenId, item.isVerified)
                      },
                    }}
                    isLast={index === lastIndex}
                  />
                )
              })}
            </Fragment>
          )
        })}

        {hasNextPage && (
          <div ref={targetRef} className="flex flex-col gap-4">
            {range(5).map(index => (
              <SearchHomeListItem.Skeleton
                key={`search-result-item-tab-content-skeleton-${index}`}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

const SearchResultItemTabContentSkeleton = () => {
  return (
    <section className="px-4">
      <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {range(5).map(index => (
          <SearchHomeListItem.Skeleton key={`search-result-item-tab-content-skeleton-${index}`} />
        ))}
      </div>
    </section>
  )
}

const SearchResultItemTabContentErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-search'])

  return (
    <>
      <Space h={24} />
      <section className="px-4">
        <CommonErrorFallback
          error={error}
          bgColor="bg-appBgSecondary"
          handleRetry={resetErrorBoundary}
          title={t('common:error-data-items')}
        />
      </section>
    </>
  )
}

const SearchResultWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary FallbackComponent={SearchResultItemTabContent.ErrorFallback} onReset={reset}>
      <Suspense fallback={<SearchResultItemTabContent.Skeleton />}>
        <SearchResultItemTabContent />
      </Suspense>
    </ErrorBoundary>
  )
}

export default SearchResultItemTabContent

SearchResultItemTabContent.Design = SearchResultItemTabContentDesign
SearchResultItemTabContent.Skeleton = SearchResultItemTabContentSkeleton
SearchResultItemTabContent.WithErrorBoundary = SearchResultWithErrorBoundary
SearchResultItemTabContent.ErrorFallback = SearchResultItemTabContentErrorFallback
