import { Space } from '@mantine/core'

import SearchResultCollection from './SearchResultCollection'
import SearchResultItem from './SearchResultItem'
import SearchResultOriginals from './SearchResultOriginals'

const SearchResultAllTabContent = () => {
  return (
    <>
      <Space h={24} />
      <SearchResultItem.WithErrorBoundary />
      <Space h={60} />
      <SearchResultCollection.WithErrorBoundary />
      <Space h={60} />
      <SearchResultOriginals.WithErrorBoundary />
    </>
  )
}

export default SearchResultAllTabContent
