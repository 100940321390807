import { Space } from '@mantine/core'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Suspense, useCallback } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { CommonErrorFallback } from '@/components/common'
import { useGetSearchOnboardings } from '@/shared/services/strapi'
import { ISearchOnboardingsData } from '@/shared/types'

import SearchHomeListItem from '../SearchHomeListItem'
import SearchSectionTitle from './SearchSectionTitle'

const Onboardings = () => {
  const { push } = useRouter()
  const { data } = useGetSearchOnboardings()

  const handleClick = useCallback(
    (url: string | null, isInternalUrl: boolean) => {
      if (url === null || window === undefined) return
      if (isInternalUrl) {
        push(url)
      } else {
        window.location.href = url
      }
    },
    [push],
  )

  if (data === undefined) return null
  return <Onboardings.Design data={data} handleClick={handleClick} />
}

const OnboardingsDesign = ({
  data,
  handleClick,
}: {
  data: ISearchOnboardingsData
  handleClick: (url: string | null, isInternalUrl: boolean) => void
}) => {
  const { t } = useTranslation(['common', 'web-search'])

  const { title, onboardings } = data

  const lastIndex = onboardings.length - 1
  if (onboardings.length === 0) return null

  return (
    <div className="px-4">
      <SearchSectionTitle title={title ?? `${t('web-search:search.on-boarding')}`} />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {onboardings.map((onboarding, index) => (
          <SearchHomeListItem
            isLast={index === lastIndex}
            key={`onboardings-${onboarding.title ?? `${t('web-search:search.on-boarding')}`}`}
            content={{
              title: onboarding.title,
              description: onboarding.description,
              image: onboarding.image,
              handleClick: () => {
                const url = onboarding.internalUrl ?? onboarding.externalUrl
                const isInternalUrl = onboarding.internalUrl !== null
                handleClick(url, isInternalUrl)
              },
            }}
            descriptionDirection="bottom"
            descriptionWrapperClass="text-sm leading-[18px]"
          />
        ))}
      </div>
    </div>
  )
}

const OnboardingsSkeleton = () => {
  return (
    <div className="px-4">
      <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={24} />
      <div className="flex flex-col gap-4">
        {range(5).map(index => (
          <SearchHomeListItem.Skeleton key={`onboarding-skeleton-${index}`} />
        ))}
      </div>
    </div>
  )
}

const OnboardingsErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['web-search', 'common'])
  return (
    <div className="px-4">
      <SearchSectionTitle title={t('web-search:search.on-boarding')} />

      <Space h={24} />

      <CommonErrorFallback
        bgColor="bg-appBgSecondary"
        title={t('common:common.error-data')}
        handleRetry={resetErrorBoundary}
        error={error}
      />
    </div>
  )
}

const OnboardingsWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary FallbackComponent={Onboardings.ErrorFallback} onReset={reset}>
      <Suspense fallback={<Onboardings.Skeleton />}>
        <Onboardings />
      </Suspense>
    </ErrorBoundary>
  )
}

export default Onboardings

Onboardings.WithErrorBoundary = OnboardingsWithErrorBoundary
Onboardings.Skeleton = OnboardingsSkeleton
Onboardings.ErrorFallback = OnboardingsErrorFallback
Onboardings.Design = OnboardingsDesign
