import { Space } from '@mantine/core'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Suspense, useCallback, useMemo } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { CommonErrorFallback } from '@/components/common'
import { ROUTE_PATH } from '@/shared/constants'
import { SEARCH_RESULT_TAB_KEY } from '@/shared/constants/search'
import { useGetSearchResultOriginalsList } from '@/shared/services/search'
import { useAppDispatch, useAppSelector } from '@/shared/store/hooks'
import { selectSearchText, setCurrentTab } from '@/shared/store/search'
import {
  ISearchResultOriginalsListRequest,
  ISearchResultOriginalsListResponse,
} from '@/shared/types/search'

import NoResult from '../../NoResult'
import OriginalsItem from '../../OriginalsItem'
import SearchSectionTitle from '../../SearchHomeContent/SearchSectionTitle'

interface ISearchResultOriginalsDesignProps {
  data?: ISearchResultOriginalsListResponse
  handleClick: (collectionAddress: string) => void
  handleCurrentTab: () => void
}

const SearchResultOriginals = () => {
  const { push } = useRouter()
  const dispatch = useAppDispatch()
  const searchText = useAppSelector(selectSearchText)

  const handleCurrentTab = useCallback(() => {
    dispatch(setCurrentTab(SEARCH_RESULT_TAB_KEY.originals))
  }, [dispatch])

  const params: ISearchResultOriginalsListRequest | null = useMemo(() => {
    if (searchText === '') return null
    return {
      searchText,
      page: 1,
      perPage: 5,
      order: 'CREATED_AT',
      orderBy: 'DESC',
    }
  }, [searchText])
  const { data } = useGetSearchResultOriginalsList(params)

  const handleClick = useCallback(
    (originalsId: string) => {
      push(ROUTE_PATH.ORIGINALS_BRAND_PAGE.replace(':originalsId', originalsId))
    },
    [push],
  )

  return (
    <SearchResultOriginals.Design
      data={data}
      handleClick={handleClick}
      handleCurrentTab={handleCurrentTab}
    />
  )
}

const SearchResultOriginalsDesign = ({
  data,
  handleClick,
  handleCurrentTab,
}: ISearchResultOriginalsDesignProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  if (data === undefined || (data && data.originals.length === 0))
    return (
      <section className="px-4 py-6">
        <SearchSectionTitle
          title={t('common:common.label-originals')}
          moreText={t('common:common.cta-label-view-all')}
          moreTextHandler={handleCurrentTab}
        />
        <Space h={16} />
        <NoResult bgColor="secondary" />
      </section>
    )

  const { originals } = data

  return (
    <>
      <section className="px-4">
        <SearchSectionTitle
          title={t('common:common.label-originals')}
          moreText={t('common:common.cta-label-view-all')}
          moreTextHandler={handleCurrentTab}
        />
        <Space h={24} />
        <div className="flex flex-col gap-4">
          {originals.map(original => (
            <OriginalsItem
              image={original.image}
              handleClick={handleClick}
              chainId={original.chainId}
              originalsId={original.originalsId}
              categoryTitle={original.categoryTitle}
              originalsTitle={original.originalsTitle}
              key={`search-result-all-tab-originals-${original.originalsId}`}
            />
          ))}
        </div>
      </section>

      <Space h={60} />
    </>
  )
}

const SearchResultOriginalsSkeleton = () => {
  return (
    <section className="px-4">
      <div className="w-[100px] h-[30px] rounded-[2px] bg-appBgQuaternary" />
      <Space h={24} />
      <div className="flex flex-col gap-5">
        {range(5).map(index => (
          <OriginalsItem.Skeleton key={`search-result-all-tab-originals-skeleton-${index}`} />
        ))}
      </div>
    </section>
  )
}

const SearchResultOriginalsErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-search'])
  const dispatch = useAppDispatch()

  const handleCurrentTab = useCallback(() => {
    dispatch(setCurrentTab(SEARCH_RESULT_TAB_KEY.originals))
  }, [dispatch])

  return (
    <section className="px-4">
      <SearchSectionTitle
        title={t('common:common.label-originals')}
        moreText={t('common:common.cta-label-view-all')}
        moreTextHandler={handleCurrentTab}
      />
      <Space h={24} />
      <CommonErrorFallback
        error={error}
        bgColor="bg-appBgSecondary"
        handleRetry={resetErrorBoundary}
        title={t('common:common.error-data')}
      />
    </section>
  )
}

const SearchResultWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()
  return (
    <ErrorBoundary FallbackComponent={SearchResultOriginals.ErrorFallback} onReset={reset}>
      <Suspense fallback={<SearchResultOriginals.Skeleton />}>
        <SearchResultOriginals />
      </Suspense>
    </ErrorBoundary>
  )
}

export default SearchResultOriginals

SearchResultOriginals.Design = SearchResultOriginalsDesign
SearchResultOriginals.Skeleton = SearchResultOriginalsSkeleton
SearchResultOriginals.ErrorFallback = SearchResultOriginalsErrorFallback
SearchResultOriginals.WithErrorBoundary = SearchResultWithErrorBoundary
