import { useQuery } from '@tanstack/react-query'

import { IStrapiBrandPageBaseResponse, IStrapiBrandPageResponseType } from '@/shared/types'

import { withStrapiAxios } from '../api'

export const getBrandPageItem = async ({ originalsId }: { originalsId?: string }) => {
  const url = `/api/originals/${originalsId}`
  const {
    data: {
      data: { attributes },
    },
  } = await withStrapiAxios<IStrapiBrandPageBaseResponse<IStrapiBrandPageResponseType>>({ url })
  return attributes
}

export const useGetBrandPageItem = (pathname: string, originalsId?: string) => {
  return useQuery(
    [`getPublicUtilities-${pathname}`, { originalsId }],
    () => getBrandPageItem({ originalsId }),
    {
      enabled: originalsId !== undefined,
      refetchOnWindowFocus: false,
    },
  )
}
